import React from 'react';
import classes from "./HeroInitiative.module.css";
// import Herodriveprops from '../../components/Herodriveprops/Herodriveprops'

const HeroInitiative = () => {
    return (
        <div className={classes.outContainer}>
        <div className={classes.container}>
            <h1>Mayframe Development Initiative</h1>
            <h4>HOME/INITIATIVE</h4>
        </div>
        </div>
      )
}

export default HeroInitiative
